<template>
  <div class="dashboard">
    <v-navigation-drawer
      v-model="drawer"
      color="#FB9300"
      class="fullheight"
      width="256"
      app
    >
      <div class="sizeLogo d-flex justify-content-center align-center">
        <img class="cover" src="./../assets/logo.png" alt="" />
      </div>
      <v-list dense nav class="">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          tag="router-link"
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-group :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>List Paket</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon, to], i) in listPaket"
            :key="i"
            link
            :to="to"
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group> -->
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialogConfirm" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">warning!</span>
        </v-card-title>
        <v-card-text> Anda yakin ingin keluar? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogConfirm = false">
            Cancel
          </v-btn>
          <v-btn @click="signOut()" color="red darken-1" text> Log Out </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar hide-on-scroll app fixed height="75px">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <!-- <v-btn text router>
          <v-chip class="ma-2" color="primary" outlined pill>
            User Account
            <v-icon right> mdi-account-outline </v-icon>
          </v-chip>
        </v-btn> -->

        <v-menu class="text-center" transition="scroll-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text router>
              <v-chip
                class="m-2"
                router
                color="#FB9300"
                v-bind="attrs"
                v-on="on"
                outlined
                pill
              >
                {{ profileName }}
                <v-icon right> mdi-account-outline </v-icon>
              </v-chip>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group active-class="border" color="indigo">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="dialogConfirm = true">
                <v-list-item-icon>
                  <v-icon>mdi-power</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Logout </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- <v-b`tn text router @click="dialogConfirm = true">
          <v-icon>mdi-power</v-icon>
        </v-b>` -->
      </v-toolbar-items>
    </v-app-bar>

    <div class="fullheight pa-5">
      <router-view></router-view>
    </div>

    <v-snackbar v-model="snackbar" color="red" timeout="2000" bottom>
      {{ logouts }}
    </v-snackbar>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();
const user = auth.currentUser;

export default {
  name: "Dashboard",
  data() {
    return {
      profileName: "",
      drawer: true,
      snackbar: false,
      dialogConfirm: false,
      dialog: false,
      logouts: "LogOut Success",
      items: [
        { title: "Dashboard", to: "/" },
        { title: "News", to: "news" },
        { title: "Promo", to: "promo" },
        { title: "List Paket", to: "package" },
        { title: "List Point", to: "point" },
        // { title: "List Admin", to: "admin" },
      ],

      listPaket: [
        ["Internet", "mdi-account-multiple-outline", "internet-package"],
        ["Wifi", "mdi-account-multiple-outline", "wifi-package"],
        ["CCTV", "mdi-account-multiple-outline", "cctv-package"],
        ["TV", "mdi-account-multiple-outline", "tv-package"],
      ],
    };
  },

  methods: {
    signOut() {
      signOut(auth)
        .then((result) => {
          console.log("logout ", result);
          this.dialogConfirm = false;
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
        });
    },
  },

  mounted() {
    if (user !== null) {
      console.log("curentsuernew", user);
      // console.log(
      //   user.email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
      // );
      console.log(user.email.match(/^([^@]*)@/)[1]);
      this.profileName = user.email.match(/^([^@]*)@/)[1];
    } else {
      console.log("ga masuk");
    }
  },
};
</script>

<style scoped>
.fullheight {
  min-height: 100vh !important;
}
.sizeLogo {
  height: 150px;
}
.cover {
  width: 200px;
}
</style>
